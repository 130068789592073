export default {
	colors: {
		//! Primary Colors
		gradient01: '#240D55',
		gradient02: '#1E3F86',
		gradient03: '#1D4193',
		gradient04: '#102C6F',
		blue: '#222169',
		darkBlue: '#1A3C8A',
		focused: '#00DCFF',
		white: '#FFFFFF',

		//! Secondary Colors
		errorColor: '#F97381',
		successColor: '#6DECD9',

		//! Background Color
		backgroundColor: '#214593',
	},

	mediaQuery: {
		desktopSizeXL: '2560px',
		desktopSizeL: '1920px',
		desktopSizeM: '1536px',
		desktopSizeS: '1366px',
		tabletSize: '1280px',
		tabletSizeS: '768px',

		desktopSizeXLMin: '2559px',
		desktopSizeLMin: '1919px',
		desktopSizeMMin: '1535px',
		desktopSizeSMin: '1365px',
		tabletSizeMin: '1279px',
		tabletSizeSMin: '767px',
	},
};
