import { createGlobalStyle, css } from 'styled-components';

function columnGenerator(suffix) {
	let style = '';

	for (let i = 1; i <= 12; i++) {
		style += suffix ? `.col-${suffix}-${i}` : `.col-${i}`;
		style += `{ width: ${(100 / 12) * i}% }`;
	}

	return style;
}

const HelperClass = createGlobalStyle`${css`
	//! Fonts
	.font-golos-text-regular {
		font-family: var(--golos_text);
		font-weight: 400;
	}

	.font-golos-text-bold {
		font-family: var(--golos_text);
		font-weight: 600;
	}

	.font-zen-dots-regular {
		font-family: var(--zen_dots);
		font-weight: 400;
	}

	//! Primary Colors
	.gradient01-color {
		color: var(--gradient01);

		&-bg {
			background-color: var(--gradient01);
		}
	}

	.gradient02-color {
		color: var(--gradient02);

		&-bg {
			background-color: var(--gradient02);
		}
	}

	.gradient03-color {
		color: var(--gradient03);

		&-bg {
			background-color: var(--gradient03);
		}
	}

	.gradient04-color {
		color: var(--gradient04);

		&-bg {
			background-color: var(--gradient04);
		}
	}

	.focused-color {
		color: var(--focused);

		&-bg {
			background-color: var(--focused);
		}
	}

	.white {
		color: var(--white);

		&-bg {
			background-color: var(--white);
		}
	}

	.darkBlue {
		color: var(--darkBlue);

		&-bg {
			background-color: var(--darkBlue);
		}
	}

	//! Secondary Colors
	.error-color {
		color: var(--errorColor);

		&-bg {
			background-color: var(--errorColor);
		}
	}

	.success-color {
		color: var(--successColor);

		&-bg {
			background-color: var(--successColor);
		}
	}

	//! For Text
	.uppercase {
		text-transform: uppercase;
	}

	.lowercase {
		text-transform: lowercase;
	}

	.capitalize {
		text-transform: capitalize;
	}

	.underline {
		text-decoration: underline;
	}

	.text-center {
		text-align: center;
	}

	.text-left {
		text-align: left;
	}

	//!Margins
	.mT40 {
		margin-top: var(--mT40);
	}

	//!Paddings
	.pT96 {
		padding-top: var(--paddingTop96);
	}

	.pT32 {
		padding-top: var(--paddingTop32);
	}

	//! Icons
	.iconL {
		color: var(--white) !important;
		font-size: var(--h1) !important;
	}

	.iconM {
		color: var(--white);
		font-size: var(--h2);
	}

	.iconS {
		color: var(--white);
		font-size: var(--h3);
	}

	.iconXS {
		color: var(--errorColor);
		font-size: var(--h4);
	}

	.opacity07 {
		opacity: 0.7;
	}

	//! Flex Box
	.d-flex {
		display: flex;
	}

	.justify-center {
		.row {
			justify-content: center;
		}
	}

	.display-inline {
		display: inline-block;
	}

	.overflow-hidden {
		overflow: hidden;
	}

	.translate-y {
		&-110 {
			transform: translateY(110%);
		}
		&-100 {
			transform: translateY(100%);
		}
		&-50 {
			transform: translateY(50%);
		}
		&-30 {
			transform: translateY(30%);
		}
	}

	.translate-y {
		&--110 {
			transform: translateY(-110%);
		}

		&--100 {
			transform: translateY(-100%);
		}
		&--50 {
			transform: translateY(-50%);
		}
		&--30 {
			transform: translateY(-30%);
		}
	}

	.opacity {
		&-0 {
			opacity: 0;
		}
		&-07 {
			opacity: 0.7;
		}
	}

	//! Grid
	.row {
		display: flex;
		flex-wrap: wrap;
		margin: 0 calc(var(--colPadding) * -1);

		> * {
			width: 100%;
			padding: 0 var(--colPadding);
		}

		${columnGenerator()}

		@media only screen and (max-width: ${({ theme }) => theme.mediaQuery.desktopSizeXLMin}) {
			${columnGenerator('dxl')}
		}

		@media only screen and (max-width: ${({ theme }) => theme.mediaQuery.desktopSizeLMin}) {
			${columnGenerator('dl')}
		}

		@media only screen and (max-width: ${({ theme }) => theme.mediaQuery.desktopSizeMMin}) {
			${columnGenerator('dm')}
		}

		@media only screen and (max-width: ${({ theme }) => theme.mediaQuery.desktopSizeSMin}) {
			${columnGenerator('ds')}
		}

		@media only screen and (max-width: ${({ theme }) => theme.mediaQuery.tabletSizeMin}) {
			${columnGenerator('t')}
		}

		@media only screen and (max-width: ${({ theme }) => theme.mediaQuery.tabletSizeSMin}) {
			${columnGenerator('m')}
		}
	}
`}`;

export default HelperClass;
