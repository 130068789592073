export default {
	routes: {
		home: {
			path: '/',
			slug: 'home',
			name: 'home',
		},
		privacyPolicy: {
			path: '/privacy-policy',
			slug: 'privacy-policy',
			name: 'privacyPolicy',
		},
		termsOfUse: {
			path: '/terms-of-use',
			slug: 'terms-of-use',
			name: 'termsOfUse',
		},
	},
};
