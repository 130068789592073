import { createGlobalStyle, css } from 'styled-components';
import { Golos_Text, Zen_Dots } from 'next/font/google';
import theme from './theme';

//! Space Generator
function spaceGenerator() {
	let str = '';

	for (let i = 1; i <= 250; i++) {
		str += `--sp${i}x: calc(var(--spSize) * ${i});`;
	}

	return str;
}

//! Color Generator
function colorGenerator() {
	const colorsArr = Object.keys(theme.colors);

	let colorVariable = '';

	for (let i = 0; i < colorsArr.length; i++) {
		colorVariable += `--${colorsArr[i]}: ${theme.colors[colorsArr[i]]};`;
	}

	return colorVariable;
}

//! Fonts
export const golos_text = Golos_Text({
	weight: ['400', '700'],
	style: 'normal',
	subsets: ['latin'],
	display: 'swap',
});

export const zen_dots = Zen_Dots({
	weight: '400',
	style: 'normal',
	subsets: ['latin'],
	display: 'swap',
});

const Variables = createGlobalStyle`${css`
	:root {
		//! Fonts
		--golos_text: ${golos_text.style.fontFamily};
		--zen_dots: ${zen_dots.style.fontFamily};

		//! Spacings
		--spSize: 8px;

		--sp0-5x: calc(var(--spSize) * 0.5);
		--sp1-5x: calc(var(--spSize) * 1.5);
		--sp2-5x: calc(var(--spSize) * 2.5);
		--sp3-5x: calc(var(--spSize) * 3.5);
		${spaceGenerator()}

		//! Transition
        --trTime: 0.3s;

		//! Line Heights
		--lineHeightS: 1.4;
		--lineHeightM: 1.5;

		//! Colors
		${colorGenerator()}

		//! Screen Sizes
		--desktopSizeXL: ${(props) => props.theme.mediaQuery.desktopSizeXL};
		--desktopSizeL: ${(props) => props.theme.mediaQuery.desktopSizeL};
		--desktopSizeM: ${(props) => props.theme.mediaQuery.desktopSizeM};
		--desktopSizeS: ${(props) => props.theme.mediaQuery.desktopSizeS};
		--tabletSize: ${(props) => props.theme.mediaQuery.tabletSize};
		--tabletSizeS: ${(props) => props.theme.mediaQuery.tabletSizeS};

		//! Grid Column Distance
		--colPadding: calc(var(--gutter) / 2);

		//! Size Radio Button
		--sizeRadio: calc(var(--sp3x));
		--borderRadio: 6px;

		//! Form Size
		--formSize: var(--h4);
	}

	/* //! 2560 */
	@media only screen and (min-width: ${(props) => props.theme.mediaQuery.desktopSizeXL}) {
		:root {
			--h1: 64px;
			--h2: 48px;
			--h3: 32px;
			--h4: 24px;
			--h5: 20px;
			--h6: 16px;

			//! Global Variables
			--contPaddingLR: var(--sp24x);
			--gutter: var(--sp4x);
			--sectionDistance: var(--sp30x);
			--headerFixedHeight: 172px;

			//! Margins
			--mT40: var(--sp5x);
			--mT60: var(--sp7x);

			//! Paddings
			--paddingTop96: var(--sp12x);
			--paddingTop32: var(--sp4x);

			//! Size Radio Button
			--sizeRadio: calc(var(--sp4x));
			--borderRadio: 8px;
		}
	}

	/* //! 1920 */
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.desktopSizeXLMin}) and (min-width: ${(props) => props.theme.mediaQuery.desktopSizeL}) {
		:root {
			--h1: 56px;
			--h2: 40px;
			--h3: 24px;
			--h4: 20px;
			--h5: 16px;
			--h6: 14px;

			//! Global Variables
			--contPaddingLR: var(--sp17x);
			--gutter: var(--sp3x);
			--sectionDistance: var(--sp20x);
			--headerFixedHeight: 132px;

			//! Margins
			--mT40: var(--sp5x);
			--mT60: var(--sp10x);

			//! Paddings
			--paddingTop96: var(--sp10x);
			--paddingTop32: var(--sp3x);
		}
	}

	/* //! 1536 */
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.desktopSizeLMin}) and (min-width: ${(props) => props.theme.mediaQuery.desktopSizeM}) {
		:root {
			--h1: 48px;
			--h2: 32px;
			--h3: 24px;
			--h4: 20px;
			--h5: 16px;
			--h6: 14px;

			//! Global Variables
			--contPaddingLR: var(--sp11x);
			--gutter: var(--sp3x);
			--sectionDistance: var(--sp20x);
			--headerFixedHeight: 132px;

			//! Margins
			--mT40: var(--sp4x);
			--mT60: var(--sp9x);

			//! Paddings
			--paddingTop96: var(--sp10x);
			--paddingTop32: var(--sp3x);
		}
	}

	/* //! 1366 */
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.desktopSizeMMin}) and (min-width: ${(props) => props.theme.mediaQuery.desktopSizeS}) {
		:root {
			--h1: 40px;
			--h2: 28px;
			--h3: 20px;
			--h4: 16px;
			--h5: 14px;
			--h6: 14px;

			//! Global Variables
			--contPaddingLR: var(--sp14x);
			--gutter: var(--sp2x);
			--sectionDistance: var(--sp13x);
			--headerFixedHeight: 128px;

			//! Margins
			--mT40: var(--sp3x);
			--mT60: var(--sp9x);

			//! Paddings
			--paddingTop96: var(--sp7x);
			--paddingTop32: var(--sp3x);
		}
	}

	/* //! 1280 */
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.desktopSizeSMin}) and (min-width: ${(props) => props.theme.mediaQuery.tabletSize}) {
		:root {
			--h1: 40px;
			--h2: 28px;
			--h3: 20px;
			--h4: 16px;
			--h5: 14px;
			--h6: 14px;

			//! Global Variables
			--contPaddingLR: var(--sp9x);
			--gutter: var(--sp2x);
			--sectionDistance: var(--sp13x);
			--headerFixedHeight: 112px;

			//! Margins
			--mT40: var(--sp3x);
			--mT60: var(--sp13x);

			//! Paddings
			--paddingTop96: var(--sp7x);
			--paddingTop32: var(--sp3x);
		}
	}

	/* //! 768 */
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.tabletSizeMin}) and (min-width: ${(props) => props.theme.mediaQuery.tabletSizeS}) {
		:root {
			--h1: 40px;
			--h2: 24px;
			--h3: 18px;
			--h4: 16px;
			--h5: 14px;
			--h6: 14px;

			//! Global Variables
			--contPaddingLR: var(--sp4x);
			--gutter: var(--sp2x);
			--sectionDistance: var(--sp13x);
			--headerFixedHeight: 112px;

			//! Margins
			--mT40: var(--sp3x);
			--mT60: var(--sp9x);

			//! Paddings
			--paddingTop96: var(--sp6x);
			--paddingTop32: var(--sp8x);
		}
	}

	/* //! Mobile */
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.tabletSizeSMin}) {
		:root {
			--h1: 24px;
			--h2: 20px;
			--h3: 16px;
			--h4: 14px;
			--h5: 14px;
			--h6: 14px;

			//! Global Variables
			--contPaddingLR: var(--sp2x);
			--gutter: var(--sp2x);
			--sectionDistance: var(--sp10x);
			--headerFixedHeight: 83px;

			//! Margins
			--mT40: var(--sp2x);
			--mT60: var(--sp8x);

			//! Paddings
			--paddingTop96: var(--sp5x);
			--paddingTop32: var(--sp2x);

			//! Form Size
			--formSize: var(--sp2x);
		}
	}
`}`;

export default Variables;
