import template from 'lodash.template';

//* HOC's
import withLanguageContext from '@/context/consumerHOC/LanguageConsumer';

const Translate = (props) => {
	let compiled = props.languages[props.selectedLang]?.[props.val] && template(props.languages[props.selectedLang]?.[props.val]);

	return (compiled && compiled(props.vars)) || props.val || null;
};

export default withLanguageContext(Translate, '*');
